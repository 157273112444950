.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}
/*
.btn-primary{
  background-color: #85FFC4;
  color: #091E45;
  border-width: 0;
}
.btn-primary:hover{
  background-color: #51faa8;
  color: #091E45;
  border-width: 0;
}



.btn-secondary{
  background-color: #85FFC4;
  color: #091E45;
  border-width: 0;
}
.btn-secondary:hover{
  background-color: #51faa8;
  color: #091E45;
  border-width: 0;
}
.btn-secondary:focus{
  background-color: #a4ffd3;
  color: #091E45;
  border-width: 0;
}
*/